@import '../../colors.scss';

@media (min-width: 1024px) and (max-width: 1440px) {
    #magicCalendarsPage {
        section#titleSection {
            padding-top: 0;

            #magicCalendarsTitle {
                width: 55%;
                margin-top: 5%;
            }

            img#magicCalendarsTitleWand {
                width: 17vw !important;
                right: 22% !important;
                top: 22% !important;
            }

            h2 {
                padding: 12px;
                width: 29%;
            }

            #magicCalendarsSubtitle {
                width: 58%;
            }

            #magicCalendarsTitleSectionWandContainer {
                animation: hoverLarge infinite 10000ms ease-in-out;

                #magicCalendarsTitleSectionWand {
                    width: 33vw;
                }

                #magicCalendarsTitleSectionWandMagic {
                    width: 14vw;
                    position: absolute;
                    top: 34%;
                    right: -15%;
                    z-index: -1;
                }
            }

            #magicCalendarsTitleSectionComputerHands {
                width: 33%;
                left: 33%;
            }
        }

        section#personalizationSection {
            height: 100%;
            div#personalizationSectionLeft {
                h1 {
                    font-size: 4vw !important;
                }
                ul {
                    font-size:1.5vw !important;
                }
            }
            div#personalizationSectionRight {
                display: inline-flex !important;
                justify-content: center;
                img {
                    transform: none !important;
                    width: 75% !important;
                }
            }
        }

        section#whatYouGetSection {
            height: 100%;
            width: calc(100vw - 12%);
            padding-top: 0;

            #whatYouGetLeftSection {
                width: 33%;

                >span {
                    width: 23vw !important;
                }

                >h1 {
                    font-size: 4.5vw;
                }

                .whatYouGetLeftSectionSection {
                    h2 {
                        font-size: 1.6vw !important;
                    }

                    .whatYouGetLeftSectionBodySection {
                        .whatYouGetLeftSectionBodySectionBody {
                            font-size: 1.3vw !important;
                        }
                    }
                }
            }

            #whatYouGetRightSection {
                #whatYouGetRightSectionLeft {
                    height: 50% !important;
                    margin: 28% 3% 9% !important;
                }

                #whatYouGetRightSectionRight {
                    .whatYouGetRightSectionRightTitleContainer {
                        img {
                            width: 2vw !important;
                            height: 2vw !important;
                            margin-top: 0.1vw !important;
                            transform: translateX(-0.7vw) !important;
                        }

                        h2 {
                            font-size: 1.6vw !important;
                        }
                    }
                    .whatYouGetRightSectionBodySectionBody {
                        font-size: 1.4vw !important;
                    }
                }
            }
        }

        section#chooseSection {
            #chooseSectionSelectionsContainer {
                .chooseSectionSelection {
                    .chooseSectionSelectionCta {
                        font-size: 1.6vw !important;
                        padding: 3%;
                    }
                }
            }
        }

        section#innovateSection {
            #innovateSectionWandContainer {
                #innovateSectionMagic {
                    right: -14vw !important;
                    bottom: -22vw !important;
                    width: 23vw !important;
                }
            }
            #innovateSectionContentContainer {
                h1 {
                    font-size: 4.5vw !important;
                }
                p {
                    font-size: 1.8vw;
                }
                button {
                    width: 75%;
                    font-size: 1.8vw;
                }
            }
        }
    }
}

@keyframes hoverLarge {
    0% {
        left: -10%;
        top: 40%;
    }

    40% {
        left: 17%;
        top: 40%;
    }

    60% {
        left: 17%;
        top: 40%;
    }

    100% {
        left: -10%;
        top: 40%;
    }
}