.EmojiDropdownItem {
    display: flex;
    align-items: center;
    height: inherit;

    .emoji {
        width: 20px;
        height: 20px;
    }

    .shortcode {
        margin-left: 5px;
    }
}