@import '../../../colors.scss';

@media (min-width: 1024px) {
    #blogPostPageContainer {
        #blogPostContainer {
            h1 {
                margin-top: 0;
                font-size: 4vw;
            }

            p {
                font-size: 1.5vw;
            }

            #introContainer {
                margin-bottom: 25px;
            }

            #blogPostShareIconsContainer {
                margin-bottom: 100px;
            }

            #blogPostPhoto {
                margin: 0px 0 25px 0;
            }

            h3,
            h5 {
                font-size: 1.7vw !important;
            }

            h2,
            h4,
            h6 {
                font-size: 3vw;
            }

            h3,
            h5 {
                font-size: 5vw;
            }
        }

        #blogPostNavContainer {
            font-size: 2vw;
        }
    }
}