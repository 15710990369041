@import '../../../colors.scss';

@media (min-width: 640px) and  (max-width: 1024px) {
    #superVideosPageContainer {
        #superClassWorkbookDownload {
            top: -30px;

            svg {
                height: 22px;
            }

            p {
                margin-left: 10px;
                font-size: 20px;
            }
        }
    }
}