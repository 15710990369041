@import '../../../colors.scss';

.teamMemberContainer {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .teamMemberPhoto {
        width: 10vw;
        height: 10vw;
        object-fit: cover;
        border-radius: 50%;
    }

    .teamMemberTextContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin-left: 2vw;
        font-family: "Garet";
        font-size: 1.3vw;
        width: 50%;

        .teamMemberName {
            color: $pink;
            font-weight: 600;
            font-size: 1.6vw;
        }
    }
}