@import '../../../colors.scss';

@media (min-width: 1024px) {
    #superLoginPageContainer {
        h1 {
            margin: 5% auto 5% auto;
            font-size: 3.5vw;
        }

        div#superLoginFieldsContainer {
            width: 50%;
            margin-bottom: 5%;

            .superLoginField {
                &:nth-of-type(1) {
                    margin-bottom: 25px;
                }

                label {
                    font-size: 2vw;
                }

                input {
                    min-height: 50px;
                    width: 75%;
                    font-size: 1.5vw;
                    padding: 0 20px;
                }
            }
        }

        div#superLoginButtonContainer {
            position: relative;

            button {
                font-size: 1.8vw;
                padding: 0.5% !important;
                width: 10%;
            }

            p#superClassLoginError {
                font-size: 1.8vw;
            }
        }
    }
}