@import '../../colors.scss';

@media (min-width: 640px) and  (max-width: 1023px) {
    #contactUsPage {
        h1#contactUsPageTitle {
            font-size: 4vw;
        }

        div#contactUsFormContainer {
            flex-direction: column;
            align-items: center;

            section {
                width: 80%;

                .contactUsFormGroup {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 2.5%;

                    label {
                        font-size: 3vw;
                        margin-bottom: 2%;
                    }

                    input {
                        height: 5vh;
                        padding-left: 2%;
                        font-size: 2.5vw;
                    }

                    textarea {
                        height: 20vh;
                        padding-left: 2%;
                        font-size: 2.5vw;
                    }
                }

                button {
                    padding: 2.5%;
                    font-size: 4vw;
                    margin-top: 2.5%;
                }
            }
        }
    }
}