@import '../../../colors.scss';

#superVideosPageContainer {
    background: black;
    color: white;
    box-shadow: inset 0 0 0 1px $yellow;
    padding: 15% 10%;
    padding-bottom: 0;
    font-family: 'Garet';
    min-height: 100vh;

    > a:nth-of-type(1) {
        filter: drop-shadow(0px 1px 3px $white);
        position: absolute;
        left: 25px;
        top: 0;
    }

    #superClassVideosHello {
        position: absolute;
        left: 10%;
        top: 5%;
        font-family: 'Hatton';
        font-size: 2vw;
    }

    .superClassLogoutButton {
        color: $yellow;
        font-family: 'Hatton';
        background: none;
        cursor: pointer;
        font-size: 2vw;
        position: absolute;
        top: 5%;
        right: 10%;
    }

    #superClassWorkbookDownload {
        position: absolute;
        display: flex;
        top: -25px;

        p {
            margin-left: 10px;
            font-family: 'Garbata';
            font-size: 15px;

            &:hover {
                color: $yellow;
            }
        }
    }

    .superClassVideosContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
    }

    h1 {
        font-size: 6vw;
        margin-bottom: 10%;
        font-family: 'Garbata';
        font-weight: 100;
        text-align: center;

        span {
            color: $yellow;
            font-weight: 300;
        }
    }
    
    span#superClassProgressLoader {
        margin: auto;
        margin-bottom: 10%;
    }
    
    div#superVideosContinueContainer {
        width: 50%;
        margin: auto;
        margin-bottom: 12.5%;
        display: flex;
        border: 1px solid;
        flex-direction: column;
        align-items: center;
        padding: 5%;
        cursor: pointer;
        font-size: 2.5vw;
        font-family: 'Hatton';

        svg {
            height: 6vw;
            margin-bottom: 5%;
            color: $yellow;
        }
    }

    .scroll-to-top {
        background: $yellow;
    }
}