@import '../../../colors.scss';

#navbar {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2vh 0;
    background: white;
    z-index: 97;

    &.burger {
        width: 85vw;
        flex-direction: column;
        margin-top: 10%;
        position: absolute;
        top: 25vh;
        left: 5vw;
        overflow: hidden;
        z-index: 99;
        animation: cubic-bezier(0.6, -0.28, 0.735, 0.045) 500ms slideDown;

        .navbarSection {
            flex-direction: column;
            padding: 0;
            width: 100%;
            text-align: center;

            .navbarLink {
                font-size: 8vw;
            }
        }
    }
}

.navbarSection {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }

    30% {
        opacity: 1;
        transform: translateY(30px);
    }

    60% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}