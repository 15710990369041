@import '../../colors.scss';

@media (min-width: 640px) and  (max-width: 1024px) {

    section#classAbout {
        .videoContainer h2 {
            font-size: 1.2vw;
        }

        .right {
            font-size: 1.9vmin;
        }
    }
}