@import '../../colors.scss';

#signupPageContainer {
    background: $yellow;

    input {
        color: $pink;
    }

    #signupButton {
        color: white;
        background: $pink;
        box-shadow: 0vw 5px 0vw 0vw $yellow, 0vw 10px 0vw black;
    }
}