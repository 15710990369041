@import '../../../colors.scss';

#blogPostPageContainer {
    position: relative;

    #blogPostContainer {
        padding: 5vw 10vw;

        h1 {
            text-align: center;
            font-size: 4.5vw;
            margin-bottom: 4vw;
        }

        #introContainer {
            margin-bottom: 50px;
        }

        #mobileMenuShadow {
            animation: cubic-bezier(0.455, 0.03, 0.515, 0.955) 1000ms fadeIn;
            background: black;
            width: 100%;
            height: 100%;
            z-index: 98;
            position: absolute;
            cursor: n-resize;
        }

        p {
            font-family: 'Garet';
            font-size: 2.5vw;
            margin-bottom: 2.5vw;
        }

        #blogPostShareIconsContainer {
            display: flex;
            justify-content: space-around;
            margin-bottom: 100px;

            #blogPostCopyIcon {
                width: 40px !important;
                height: 50px;
            }

            button svg,
            img {
                width: 50px;
                cursor: pointer;
            }
        }

        #blogPostPhoto {
            width: 100%;
            margin: 0px auto 50px auto;
            border: 3px solid black;
            max-width: 500px;
            display: flex;
        }

        #youtubeEmbedContainer {
            margin: 4vw 0;
        }

        h2 {
            margin-bottom: unset;
        }

        h3,
        h5 {
            font-family: 'Garet';
            font-size: 2.9vw;
        }

        h2,
        h4,
        h6 {
            font-family: "Hatton";
            font-size: 3.3vw;
            margin: 12px 0;
        }

        h3,
        h5 {
            color: black;
            font-size: 3vw;
        }

        p,
        ul,
        ol,
        li {
            font-family: 'Garet';
        }

        li {
            list-style: disc;
        }

        blockquote {
            font-style: italic;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }

        #conclusionContainer {
            margin-bottom: 100px;
        }
    }

    #blogPostNavContainer {
        font-family: 'Garbata';
        font-weight: 600;
        font-style: italic;
        color: $pink;
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        font-size: 3vw;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}