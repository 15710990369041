@import '../../../../colors.scss';

#magicCheckoutForm {
    border: 1px solid $pink;
    padding: 2%;
    border-radius: 15px;
    background: $pinkFaded;
    margin: 2% 0;

    .magicPaymentElement {
        label {
            font-family: 'Garet' !important;
        }
    }

    #magicCheckoutSubmitButton {
        background: $green;
        color: white;
        font-size: 4vw;
        padding: 13px;
        cursor: pointer;
        font-family: 'Garbata';
        font-weight: bold;
        margin: 5%;
        margin-bottom: 0;
        width: 90%;

        &.disabled {
            background: $blackFaded !important;
            cursor: not-allowed !important;
            font-size: 3vw;
            color: #0000006b;
        }
    }

    p.error {
        text-align: center;
        font-size: 3vw;
        margin-top: 5%;
        font-family: 'Hatton';
        color: $pink;
    }
}