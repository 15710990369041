@import '../../../colors.scss';

@media (max-width: 640px) {
    #blogPostPageContainer {
        #blogPostContainer {
            h1 {
                margin-top: 15px;
                font-size: 8vw;
            }

            p {
                font-size: 4.5vw;
            }

            #introContainer {
                margin-bottom: 25px;
            }

            #blogPostShareIconsContainer {
                margin-bottom: 100px;
            }

            #blogPostPhoto {
                margin: 0px 0 25px 0;
            }

            h3,
            h5 {
                font-size: 4.7vw !important;
            }

            h2,
            h4,
            h6 {
                font-size: 6vw;
            }

            h3,
            h5 {
                font-size: 5vw;
            }
        }

        #blogPostNavContainer {
            font-size: 4.5vw;
        }
    }
}