@import '../../../colors.scss';

.superThumbnailContainer {
    display: flex;

    .superThumbnail {
        margin-bottom: 25px; 
        position: relative;
        img {
            width: 33vw;
            border: 1px solid;
        }
        svg {
            position: absolute;
            right: 0;
            top: 0;
            width: 3vw;
            height: 3vw;
        }
    }

    .superThumbnailTitleContainer {
        margin-left: 25px; 
        text-align: left;

        h2 {
            font-size: 2.2vw;
            font-family: 'Garbata';

            &:hover {
                text-decoration: underline;
            }

            .superThumbnailTitleNumber {
                font-family: 'Hatton';
                color: $yellow;
            }
            font-size: 2.5vw;
        }

        p {
            font-family: 'Garet';
            font-size: 2vw;
        }
    }
}