@media (max-width: 640px) {
    #servicesPage {
        section#servicesTitleSection {
            margin-bottom: 10%;
        }

        .servicesSection {
            flex-direction: column-reverse;

            label {
                font-size: 2.5vw;
            }

            h2 {
                font-size: 5.2vw;
            }

            p {
                margin-top: 5%;
                font-size: 3.8vw;
            }

            .servicesSectionLeft,
            .servicesSectionRight {
                display: flex;
                width: 90%;
            }

            .servicesSectionLeft {


                img {}
            }

            .servicesSectionRight {}
        }

        section#servicesTitleSection {
            flex-direction: column;
            margin-bottom: 5% !important;

            div#servicesTitleLeft {
                width: 80%;
                padding: 10%;

                h1 {
                    font-size: 5.5vw;
                }

                p {
                    font-size: 3.8vw;
                    margin-top: 5%;
                }

                button {
                    font-size: 4vw;
                    padding: 3%;
                }
            }

            div#servicesTitleRight {
                width: 100%;
                padding-left: 0%;
                display: flex;
                justify-content: center;

                #servicesTitleVideo {
                    margin-top: -8%;
                    width: 95%;
                }
            }
        }

        section#servicesDigitalSection {
            #servicesDigitalLeft {
                img {
                    margin-top: 0;
                }
            }
        }

        section#servicesAccelerationSection {
            #servicesAccelerationLeft {
                img {
                    margin-top: 0;
                }
            }
        }

        section#servicesPlanSection {
            h3 {
                font-size: 4vw;
            }

            ul#servicesPlanBulletList {
                li.servicesPlanBullet {
                    font-size: 3.8vw;
                    align-items: flex-start;

                    img {
                        transform: translateY(1vw);
                    }
                }

                ul#servicesPlanBulletOneList {
                    li {
                        font-size: 3vw;
                    }
                }
            }
        }

        section#servicesPlansAndPricesSection {
            h3 {
                font-size: 7vw;
                text-align: left;
            }

            div#servicesPlansAndPricesContainer {
                flex-direction: column;

                .servicesPlansAndPrices {
                    width: 100%;

                    .servicesPlansAndPricesContent {
                        h4 {
                            font-size: 4.4vw;
                            width: 67%;
                        }

                        label {
                            top: 6%;
                            font-size: 3.8vw;
                        }

                        p {
                            margin: 5% 0;
                            font-size: 3.8vw;
                        }

                        ul.servicesPlansAndPricesList {
                            li.servicesPlansAndPricesItem {
                                font-size: 3.8vw;
                            }
                        }
                    }

                    a.checkoutButton {
                        font-size: 4vw;
                        padding: 2.5%;
                        width: fit-content;
                        margin: 5% 0;
                    }
                }
            }
        }
    }

    section#servicesUgcPackagesSection {
        flex-direction: column;
        margin-top: 10%;

        video#servicesUgcPackagesVideo {
            width: 50% !important;
            border-radius: 50px !important;
            padding: 15px !important;
            border: 3px solid !important;
            margin: 10% auto 15%;
        }

        div#servicesUgcPackagesRight {
            transform: none !important;

            ul {
                margin: 5% 0 !important;
            }
        }

        .servicesUgcPackage {
            width: 100% !important;

            h4 {
                font-size: 4.4vw !important;
                width: 67%;
                margin: 0 !important;

                span.servicesUgcTitleNumber {
                    font-size: 4.1vw !important;
                }
            }

            label {
                top: 3% !important;
                font-size: 3.8vw !important;
                right: 20% !important;
            }

            p {
                margin: 5% 0 5% !important;
                font-size: 3.8vw !important;
            }

            ul {
                margin-top: 0 !important;
                li {
                    font-size: 3.8vw !important;
                }
            }
        }

        a.checkoutButton {
            font-size: 4vw !important;
            padding: 2.5% !important;
            width: fit-content !important;
            margin: 5% 0 !important;
        }
    }

    #servicesAlexTestimonial {}

    .servicesTestimonial {
        .left {}

        p {
            font-size: 3vw !important;
            width: 95% !important;
        }

        .right {}

        .testimonialLabel {
            font-size: 3vw !important;
        }

        img.testimonialPhone {
            width: 80% !important;
        }
    }

    section#servicesContactSection {
        flex-direction: column;

        div#servicesContactLeft {
            width: 80% !important;

            h4 {
                font-size: 3.5vw !important;
            }

            h3 {
                font-size: 7vw !important;
            }

            p#servicesContactDescription {
                font-size: 3.8vw !important;
            }

            p#servicesContactTagline {
                font-size: 5vw !important;
            }

            div.servicesContactButton {
                font-size: 2.5vw !important;

                img {
                    width: 2vw !important;
                }
            }
        }

        div#servicesContactRight {
            width: 100% !important;
            display: flex !important;
            display: none !important;
            justify-content: center;

            img {
                width: 75%;
                margin: 0 !important;
            }
        }
    }
}