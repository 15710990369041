@import '../../colors.scss';

@media (max-width: 640px) {
    main#superClassPage {

        h1 {
            font-size: 6.5vw;
        }
        
        .superClassTestimonialContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            p {
                font-size: 3vw;
                width: 90% !important;
                margin: 5% 0px !important;
            }

            div {
                h4 {
                    font-size: 5vw;
                }

                h5 {
                    font-size: 3vw;
                    margin: 1% 0;
                }

                h6 {
                    font-size: 2.5vw;
                }
            }
        }

        a {
            font-size: 4vw;
            width: 50%;

            &#superClassCta2 {
                width: 50%;
            }
        }

        div#superClassClassContentContainer {
            h2 {
            margin-top: 10%;  
            }
        }

        ul#superClassFeaturesList,
        ul#superClassPerfectForList,
        #superClassClassContentTechnicalitiesContainer,
        #superClassClassContentImportantiesContainer {
            li {
                font-size: 3.3vw;
    
                img {
                    transform: translate(-1vw, 0.5vw);
                    width: 3.5vw !important;
                }
            }
        }

        div#superClassPerfectForListContainer {
            #superClassPerfectForList {
                transform: none;
            }
        }
    }
}