@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Extrabold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-ExtraboldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Extralight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-ExtralightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Medium.ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-MediumItalic.ttf');
    font-weight: normal;
    font-style: 'italic';
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-Thin.ttf');
    font-weight: '100';
    font-style: normal;
}

@font-face {
    font-family: 'Garbata';
    src: url('../constants/fonts/garbata/GarbataTrial-ThinItalic.ttf');
    font-weight: '100';
    font-style: 'italic';
}

@font-face {
    font-family: 'Garet';
    src: url('../constants/fonts/garet/Garet-Book.otf'),
        url('../constants/fonts/garet/Garet-Book.ttf'),
        url('../constants/fonts/garet/Garet-Book.woff'),
        url('../constants/fonts/garet/Garet-Book.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Garet';
    src: url('../constants/fonts/garet/Garet-Heavy.otf'),
        url('../constants/fonts/garet/Garet-Heavy.ttf'),
        url('../constants/fonts/garet/Garet-Heavy.woff'),
        url('../constants/fonts/garet/Garet-Heavy.woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Hatton';
    src: url('../constants/fonts/hatton/PP Hatton Bold 700.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Hatton';
    src: url('../constants/fonts/hatton/PP Hatton Medium 500.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Hatton';
    src: url('../constants/fonts/hatton/PP Hatton Ultralight 200.otf');
    font-weight: 200;
    font-style: normal;
}