@import '../../colors.scss';

#homeBurgerIcon,
.minifiedPhoto,
#outer-container>div {
    display: none;
}

#landingMinifiedBackground,
#bioMinifiedPhoto {
    display: none;
}

#homePageContainer {
    position: relative;
    overflow: hidden;
}

#mobileMenuShadow {
    animation: cubic-bezier(0.455, 0.03, 0.515, 0.955) 1000ms fadeIn;
    background: black;
    width: 100%;
    height: 100%;
    z-index: 98;
    position: absolute;
    cursor: n-resize;
}

.homeSection {
    animation: fadeIn 2000ms ease-in-out;
    height: 100vh;
    overflow: hidden;
}

img.halfSection {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.centerText {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 10%;
    align-items: center;
    width: 80%;
    height: 100%;
}

.minifiedPhoto {
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
    object-fit: cover;
    display: block;
}

section#landing {
    display: flex;
    background: white;
    overflow: unset !important;
    height: fit-content !important;

    .left {
        #dalmadanielaTitle {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex-direction: column;
            height: 100%;
            width: 67%;
            margin: auto;
            margin-top: 36%;

            .animate__animated {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            #dalmadanielaLogoContainer {
                display: flex;
                align-items: center;
                position: relative;

                #dalmadanielaTitleFaceIcon {
                    width: 5vw;
                    filter: brightness(0);
                }

                h3 {
                    font-family: 'Garet';
                    font-weight: 100;
                    font-size: 1.2vw;
                }
            }

            h1 {
                font-size: 2.4vw;
                font-family: 'Hatton';
                color: $pink;
                font-weight: 400;
                text-align: left
            }

            p {
                color: black;
                font-family: "Garet";
                font-weight: 300;
                text-align: left;
                margin-top: 2vh;
                font-size: 1.2vw;
            }
        }
    }

    .right {
        img {
            max-height: 100vh;
            object-position: top;
        }

        #dalmaTitle {
            font-family: "Garet";
            text-align: center;
            font-size: 1.3vw;
        }
    }
}

section#bio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-top: 3%;

    div#bioLeft {
        width: 33%;
        padding-left: 7%;

        h2 {
            font-family: 'Hatton';
            font-weight: 400;
            font-size: 2.4vw;
            color: $pink;
        }

        p {
            font-family: "Garet";
            font-weight: 300;
            font-size: 1.2vw;
            margin-top: 3vh;
        }

        a {
            font-family: "Garbata";
            font-weight: 700;
            font-size: 1.2vw;
            background: $green;
            color: white;
            padding: 1vw 2vw;
            display: block;
            width: fit-content;
            margin: 10% 0;
        }
    }

    div#bioRight {
        display: flex;
        justify-content: center;
        width: 66%;

        img {
            width: 43%;
            height: max-content;
        }

        #bioRightVideo {
            margin: 5%;
        }
    }
}

div#onSetCollaborated {
    h3 {
        font-size: 1.2vw;
        text-align: center;
    }

    div#onSetCollaboratedContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        img {
            width: 12%;
        }
    }
}

section#music {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-top: 5%;
    margin-bottom: 5%;

    div#musicLeft {
        width: 33%;
        padding-left: 7%;

        h2 {
            font-family: 'Hatton';
            font-weight: 400;
            font-size: 2.4vw;
            width: 90% !important;
            color: $pink;
        }

        p {
            font-family: "Garet";
            font-weight: 300;
            font-size: 1.2vw;
            margin-top: 3vh;
        }

        a {
            font-family: "Garbata";
            font-weight: 700;
            font-size: 1.2vw;
            background: $green;
            color: white;
            padding: 1vw 2vw;
            display: block;
            width: fit-content;
            margin: 10% 0;
        }
    }

    img {
        width: 40%;
        padding-right: 11%;
    }
}

section#brands {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: fit-content;
    padding: 5vh 0;
    background: white;

    #brandsCollaborated {
        margin-top: 4%;
    }

    #brandsHelped .brandLogo {
        width: 12%;
    }

    #brandsCollaborated .brandLogo {
        width: 8%;
    }

    #brandsFeatured .brandLogo {
        width: 10%;

        .brandLogoImage {
            width: 100%;
        }
    }

    .brandLogosContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }

    #brandsHelped .brandLogosContainer {
        padding: 0 10vw;
    }

    #brandsCollaborated .brandLogosContainer {
        padding: 0 3vw;
    }

    #brandsFeatured .brandLogosContainer {
        padding: 0 5vw;
    }

    #brandsHelped>div>img:nth-child(7) {
        transform: scale(1.5);
    }

    #brandsHelped>div>img:nth-child(1) {
        transform: scale(1.3) translateY(-3px);
    }

    #brandsCollaborated>div>img:nth-child(1) {
        transform: scale(0.8);
    }

    #brandsCollaborated>div>img:nth-child(2) {
        transform: scale(0.9);
        filter: brightness(0);
    }

    #brandsCollaborated>div>img:nth-child(3) {
        transform: scale(0.8);
    }

    #brandsCollaborated>div>img:nth-child(4) {
        transform: scale(0.5);
    }

    #brandsCollaborated>div>img:nth-child(5) {
        filter: drop-shadow(1px 1px 1px 1px black)
    }

    #brandsCollaborated>div>img:nth-child(6) {
        transform: scale(0.7);
    }

    #brandsCollaborated>div>img:nth-child(7) {
        transform: scale(0.8);
    }

    #brandsCollaborated>div>img:nth-child(8) {
        transform: scale(0.95);
    }

    #brandsCollaborated>div>img:nth-child(9) {
        transform: scale(0.7);
        filter: brightness(0);
    }

    #brandsCollaborated>div>img:nth-child(10) {
        transform: scale(0.8);
    }

    #brandsFeatured>div>a:nth-child(3)>img {
        transform: scale(0.7);
    }

    #brandsFeatured>div>a:nth-child(7)>img {
        transform: scale(0.7);
        filter: brightness(0);
    }

    #brandsFeatured>div>a:nth-child(8)>img {
        filter: brightness(0);
        transform: scale(1.3);
    }

    #brandsHelped h3 {
        margin-bottom: 2.5%;
    }

    #brandsHelped h3,
    #brandsCollaborated h3,
    #brandsFeatured h3,
    #brandsFeatured h4 {
        font-family: "Hatton";
        text-align: center;
        font-size: 1.2vw;
        color: black;
    }

    #brandsFeatured h4 {
        margin: 4vh auto 0 auto;
        font-size: 2vw;
        font-family: 'Hatton';

        a:hover {
            text-decoration: underline;
            color: $green;
        }
    }

    .brandsSection {
        margin-top: 2.5%;
        margin-bottom: 0;
    }
}

#onSetCollaborated {
    img:nth-child(1) {
        margin-right: -3%;
    }
}

section#socialMedia {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: fit-content;

    p {
        font-family: 'Garet';
        font-weight: 300;
        font-size: 1.2vw;
        width: 25%;

        a {
            text-decoration: underline;

            &:hover {
                color: $pink;
            }
        }
    }

    div#socialMediaPhone {
        width: 25%;
        display: flex;
        flex-direction: column;
        position: relative;

        div {
            display: flex;
            justify-content: space-between;
            padding: 0 1vw;
            margin-bottom: 0.7vh;
            height: fit-content;
        }

        a img {
            width: 2vw;
            height: 2vw;

            &:hover {
                color: black;
            }
        }
    }

    img#socialMediaInstagramPhone {
        z-index: 97;
    }

    img#socialMediaMagic {
        position: absolute;
        bottom: -60vw;
        right: -8vw;
        height: 63vw;
    }
}

#garettTestimonial {
    background: white;
}

#garettTestimonial h2 {
    font-family: "Hatton";
    font-size: 2.4vw;
    letter-spacing: 0.2vw;
    font-weight: 500;
    transform: translateY(1vw);
}

#garettTestimonial .testimonialMagic,
#evanTestimonial .testimonialMagic {
    transform: scaleX(-1);
}

#evanTestimonial .testimonialMagic {
    right: 0%;
}

.testimonial {
    .left {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 10%;
    }

    p {
        font-size: 1.2vw;
        font-family: "Garbata";
        font-style: italic;
        width: 72%;
    }

    .right {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
    }

    .testimonialLabel {
        display: flex;
        flex-direction: column;
        font-size: 1.4vw;
        font-family: "Garbata";
        font-weight: 700;
        font-style: italic;
        color: $green;
    }

    img.testimonialPhone {
        width: 90%;
        z-index: 97;
    }
}

section#teachYou {
    display: flex;
    justify-content: center;
    position: relative;
    background: white;

    img {
        filter: opacity(0.66);
        object-fit: cover;
    }

    a#teachYouClassCta {
        font-family: 'Garbata';
        font-weight: 700;
        background: $green;
        width: 25%;
        margin: auto;
        color: white;
        padding: 1vw 2vw;
        margin-top: 1vw;
        font-size: 2vw;
        text-align: center;
        position: absolute;
        right: 5%;
        top: 15%;
    }
}

section#classAbout {
    padding: 5%;
    position: relative;
    display: flex;
    color: black;
    background: black;

    #magicAboutStars {
        position: absolute;
        left: -280px;
        top: -94px;
        transform: scaleX(-0.8) rotate(-17deg);
    }

    .videoContainer {
        overflow: hidden;
        display: flex;
        justify-content: center;
        position: relative;

        h2 {
            position: absolute;
            background: $yellow;
            color: $pink;
            text-transform: uppercase;
            margin: 5%;
            font-family: 'Hatton';
            font-size: 1.2vw;
            letter-spacing: 2px;
            text-align: center;
            padding: 0.8vw;
            border-radius: 8px;
            padding-top: 1.3vw;
        }

        #plantGrowing {
            height: 100%;
        }
    }

    .right {
        background: $yellow;
        padding: 0 5%;
        font-family: 'Garet';
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 2.1vmin;

        #mobileClassTitle {
            display: none;
        }

        span {
            display: block;

            &.part1 {
                margin-top: 5%;
                font-weight: bold;
            }

            &.part3 {
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2px;
            }

            &.part4 {
                font-weight: 700;
            }
        }

        ul {
            font-weight: 600;

            li.howTo {
                list-style: initial;
            }
        }

        a {
            background: black;
            color: $yellow;
            display: block;
            width: 100%;
            text-align: center;
            font-size: 2vw;
            font-weight: 600;
            font-family: 'Garbata';
            margin-bottom: 5%;
            padding: 2% 0;

            &:hover {
                background: $pink;
                color: black;
            }

            &:active {
                background: $pink;
                color: $yellow;
            }
        }
    }
}

section#faqHomeSection {
    display: flex;
    overflow: unset;
    height: fit-content;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}