@import '../../../colors.scss';

.magicEmojiPickerContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .magicEmojiContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 2%;
        position: relative;

        .magicEmojiSelectionContainer {
            height: 45px;
        }

        .magicEmoji {
            font-size: 50px;
            transform: translateY(-7.5px);
        }

        svg.magicEmojiAddIcon {
            height: 50px;
        }

        svg.magicEmojiCloseIcon {
            top: -15px;
            height: 15px;
            right: -15px;
        }

        svg.magicEmojiCloseIcon,
        svg.magicEmojiAddIcon {
            position: absolute;
            cursor: pointer;
        }
    }

    .magicEmojiPickerContainer {
        position: fixed;
        z-index: 99;
        left: 25%;
        width: 50%;
        top: 10%;
        border-radius: 15px;
        box-shadow: 0px 0px 25px -20px $pink;
    }

    aside {
        width: 100% !important;
    }
}