@import '../../colors.scss';

@media (max-width: 640px) {
    .minifiedPhoto {
        display: block !important;
        border: 1px solid $yellow;
        padding: 10px;
    }

    #homeBurgerIcon {
        display: block !important;
        width: 25px !important;
        height: 25px !important;
        position: absolute !important;
        right: 35px !important;
        top: 35px !important;
        left: unset !important;
        cursor: pointer;
        z-index: 99;
        filter: brightness(0);
    }

    section#landing {
        flex-direction: column;
        height: fit-content !important;

        .left {
            width: 100%;

            #landingMinifiedBackground {
                position: absolute;
                object-fit: cover;
                object-position: right;
                right: -172%;
            }

            #dalmadanielaTitle {
                width: 100%;
                justify-content: center;
                flex-direction: column;
                margin-top: 0 !important;

                #dalmadanielaLogoContainer {
                    #dalmadanielaTitleFaceIcon {
                        width: 12vw !important;
                    }

                    h3 {
                        font-size: 4.5vw;
                    }
                }

                p {
                    font-weight: 300;
                    margin-top: 0 !important;
                    font-size: 3.8vw;
                    width: 75%;
                    margin-bottom: 2vw;
                }

                h1 {
                    margin-top: 70px !important;
                    font-size: 6vw !important;
                    width: 75%;
                    margin-bottom: 3vw;
                }
            }
        }

        .right {
            width: 100% !important;
            display: flex;

            img {
                width: 75% !important;
                margin: auto;
                margin-top: 5%;
            }

            #dalmaTitle {
                display: none;
            }
        }
    }

    section#bio {
        flex-direction: column;
        margin-top: 0 !important;

        .right {
            display: none;
        }

        .left {
            width: 100%;
        }

        div#bioLeft {
            width: 75%;
            padding: 0 5% !important;

            h2 {
                font-size: 6vw !important;
                margin-bottom: 5vw !important;
                width: 71%;
                padding-top: 0 !important;
            }

            p {
                font-size: 3.8vw !important;
            }

            a {
                font-size: 4vw !important;
            }
        }

        div#bioRight {
            flex-direction: column;
            align-items: center;
            transform: unset !important;
            width: 90%;
            margin-top: 10%;

            img {
                width: 75% !important;

                &:nth-of-type(2) {
                    margin-top: 10%;
                }
            }
        }
    }

    div#onSetCollaborated {
        margin-top: 10%;

        h3 {
            margin-bottom: 5%;
            font-size: 3.5vw !important;
        }

        div#onSetCollaboratedContainer {
            flex-wrap: wrap;

            img {
                width: 23%;
            }
        }
    }

    section#music {
        flex-direction: column;

        .right {
            display: none;
        }

        .left {
            width: 100%;
        }

        div#musicLeft {
            width: 75%;
            padding: 0 5% !important;

            h2 {
                font-size: 6vw !important;
                margin-bottom: 5vw !important;
                width: 67%;
                margin-top: 15%;
            }

            p {
                font-size: 3.8vw !important;
            }

            a {
                font-size: 4vw !important;
            }
        }

        img {
            width: 75% !important;
            padding-right: unset !important;
        }
    }

    section#brands {
        padding-top: 5%;
        padding-bottom: 10vw !important;

        #brandsHelped .brandLogo {
            width: 17% !important;
        }

        #brandsCollaborated .brandLogo {
            width: 20% !important;
        }

        #brandsFeatured .brandLogo {
            width: 18% !important;
        }

        #brandsHelped h3,
        #brandsCollaborated h3,
        #brandsFeatured h3 {
            text-align: center;
            font-size: 3.5vw !important;
            margin-bottom: 5vw !important;
        }

        #brandsCollaborated h3 {
            margin-bottom: 0 !important;
        }

        #brandsFeatured {
            margin-top: 0 !important;

            h4 {
                font-size: 4vw !important;
                width: 80% !important;
            }
        }
    }

    section#socialMedia {
        flex-direction: column-reverse;
        margin-top: 15%;

        p {
            font-size: 3.8vw !important;
            width: 75%;
            margin-top: 10%;
        }

        #socialMediaPhone {
            width: 50% !important;

            a img {
                width: 5vw !important;
                height: 5vw !important;
            }
        }
    }

    #garettTestimonial h2 {
        font-size: 11vw !important;
    }

    .testimonial {
        .left {
            padding-left: 5% !important;
        }

        p {
            font-size: 3.2vw !important;
            width: 95% !important;
        }

        .right {
            width: 45% !important;
            justify-content: center !important;
        }

        .testimonialLabel {
            font-size: 3.7vw !important;
        }

        img.testimonialPhone {
            width: 93.5% !important;
        }
    }

    section#teachYou {
        a#teachYouClassCta {
            width: 45%;
            padding: 2vw 2vw;
            margin-top: 3vw;
            font-size: 3.7vw;
            left: 0%;
            top: 50%;
        }
    }

    section#classAbout {
        height: 250vw;

        .left {
            display: none !important;
        }

        .right {
            width: 100% !important;
            padding: 0 10% !important;
            height: 100% !important;

            #mobileClassTitle {
                display: block !important;
                background: $yellow;
                color: $pink;
                text-transform: uppercase;
                font-family: 'Hatton';
                font-size: 4.5vw;
                letter-spacing: 2px;
                text-align: center;
                margin-top: 5%;
            }

            span {
                &.part1 {
                    margin-top: 0 !important;
                    font-size: 4vw !important;
                }

                &.part2 {
                    margin-bottom: 0 !important;
                    font-size: 3.8vw !important;
                }

                &.part3 {
                    font-size: 3.2vw !important;
                }

                &.part4 {
                    font-size: 4.3vw !important;
                }

                &.part5 {
                    font-size: 3vw !important;
                }
            }

            ul {
                font-size: 3.4vw !important;
            }

            a {
                font-size: 5vw !important;
                padding: 4% 0 !important;
            }
        }
    }
}