@import '../../colors.scss';

#blogPageContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;

    #blogPageCreateBlogIcon {
        width: 5vw;
        position: absolute;
        left: 25px;
        top: calc(50px + 5vw);
    }

    #blogPageTitleContainer {
        width: 50vw;
        display: flex;
        flex-direction: column;
        border: 3px solid black;
        margin: auto;
        margin-top: calc(100px + 4vw);
        position: relative;
        padding: 1.5vw 1.5vw 1.2vw;
        margin-bottom: 0;

        h1 {
            display: flex;
            align-items: center;
            height: 100%;
            font-family: 'Hatton';
            font-size: 3.5vw;
            text-transform: uppercase;
        }

        img {
            position: absolute;
            right: -7vw;
            width: 12vw;
            top: -5vw;
        }
    }

    #blogPageHilightsContainer {
        display: flex;
        justify-content: space-around;
        padding: 50px;
        box-sizing: border-box;
        flex-wrap: wrap;
        gap: 10px;

        a {
            width: 30%;
            margin: auto;
            .blogPageBlogPostContainer {
                background: white;
                height: 100%;
                border: 3px solid black;
                padding: 25px;
                box-sizing: border-box;
                position: relative;
                text-align: left;
                font-size: 1.5vw;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-direction: column;
                overflow: hidden;
                z-index: 99;
                max-width: 1000px;
                margin-bottom: 50px;
                transition: 300ms;

                &:hover {
                    background: $yellow;
                }
                &:active {
                    transform: scale(0.9);
                }

                h2 {
                    margin-bottom: 25px;
                }

                img {
                    width: 100%;
                    border: 2px solid black;
                }
            }
        }
    }

    #blogPageAllBlogPostsLink {
        position: absolute;
        bottom: 15px;
        right: 25px;
        font-family: 'Garbata';
        font-weight: 600;
        font-style: italic;
    }
}