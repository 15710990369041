@import '../../../colors.scss';

#superLoginPageContainer {
    background: black;
    color: white;
    border: 1px solid white;
    min-height: calc(100vh - 2px);
    text-align: center;
    
    h1 {
        margin: 10% auto 5% auto;
        font-family: 'Garbata';
        font-size: 4vw;

        span {
            color: $yellow;
        }
    }

    div#superLoginFieldsContainer {
        width: 50%;
        margin: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 5%;
        justify-content: space-between;

        .superLoginField {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:nth-of-type(1) {
                margin-bottom: 25px;
            }

            label {
                font-family: 'Garet';
                font-size: 2.5vw;
            }

            input {
                height: 1.5vh;
                min-height: 25px;
                width: 33vw;
                outline: $pink;
                border: solid 2px $pink;
                border-radius: 50px;
                padding: 0 10px;
                font-family: 'Garet';
                font-weight: 600;
                font-size: 1.5vw;
            }
        }
    }

    div#superLoginButtonContainer {
        position: relative;

        button {
            border: none;
            padding: 1%;
            padding-top: 1.2%;
            font-family: "Garet";
            text-transform: uppercase;
            font-weight: 600;
            cursor: pointer;
            width: 15%;
            background: $yellow;
            font-size: 2vw;

            &:hover {
                background: $pink;
                color: white;
            }
        }

        #superClassLoginLoader {
            margin: auto;
            transform: translateX(-25px);
        }

        p#superClassLoginError {
            font-size: 2.5vw;
            font-family: "Garbata";
            color: $pink;
            font-weight: 300;
            margin-top: 5%;
            font-style: italic;
        }
    }
}