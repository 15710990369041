@import '../../colors.scss';

#contactUsPage {
    min-height: 100vh;
    display: flex;

    h1#contactUsPageTitle {
        font-family: 'Garbata';
        font-size: 3vw;
        margin-bottom: 10%;
    }

    div#contactUsPageLeft {
        width: 50%;
        display: inline-flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 0 10%;
        box-sizing: border-box;

        .contactUsFormGroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.5%;
            label {
                font-family: 'Garet';
                font-size: 1.5vw;
                margin-bottom: 2%;
            }
            input {
                border: 1px solid $black;
                border-radius: 15px;
                height: 5vh;
                padding-left: 2%;
                font-family: 'Garet';
                font-size: 1.5vw;
            }
            textarea {
                border: 1px solid $black;
                border-radius: 15px;
                height: 20vh;
                padding-left: 2%;
                font-family: 'Garet';
                font-size: 1.5vw;
            }
        }
        #contactUsButtonContainer {
            display: flex;
            flex-direction: column;
            position: relative;

            button {
                cursor: pointer;
                padding: 2.5% 5%;
                font-family: 'Garbata';
                font-size: 1.5vw;
                background-color: $green;
                color: $white;
                font-weight: 600;  
                font-size: 2vw;
                width: fit-content;
                margin: 10% auto;

                &:hover {
                    background-color: $pink;
                }
            }

            p {
                margin: auto;
                color: $pink;
                font-size: 1.5vw;
                margin-top: 5%;
                font-family: 'Garbata';
                font-weight: bold;
                font-style: italic;
            }
        }
        
        #contactUsThankYouContainer {
            display: flex;
            flex-direction: column;
            text-align: center;

            p {
                font-size: 2vw;
                margin-top: 5%;
                font-family: 'Garet';
                color: $pink;
                font-style: italic;
            }
            svg#contactUsResetButton {
                height: 6vw;
                color: $pink;
                cursor: pointer;
            }
        }
    }

    div#contactUsPageRight {
        width: 50%;
        display: inline-block;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}