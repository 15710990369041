@import '../../colors.scss';

#meetTheTeamPage {
    background: $yellow;
    height: 90vh;
    padding: 5vh;
    overflow: hidden;
    display: flex;

    #homeText {
        left: unset !important;
        right: 25px;
    }

    #teamTitleContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Hatton';
        font-size: 5vw;
        height: 45vh;
        margin: 0;

        #multipotentialite {
            color: $pink;
        }

        #teamDownwardSpiral {
            position: absolute;
            top: 45vh;
            right: 0%;
            width: 45vmin;
        }
    }

    #teamMembersContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}