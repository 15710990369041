@import '../../colors.scss';

#magicCalendarsPage {
    .magicCalendarsSection {
        height: 100vh;
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    
    section#titleSection {
        text-align: center;
        padding-top: 7%;
        background: url("https://storage.googleapis.com/dalmadaniela.com/assets/magicCalendars/landingPhoto.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        
        #magicCalendarsTitleContainer {
            position: relative;
            #magicCalendarsTitle {
                width: 40vw;
                filter: drop-shadow(0px 8px 30px black);
            }

            img#magicCalendarsTitleWand {
                width: 20vw;
                position: absolute;
                transform: scaleX(-1);
                right: 14%;
                top: 25%;
                filter: drop-shadow(1px 7px 7px black);
            }
        }

        h2 {
            font-family: 'Garet';
            text-transform: uppercase;
            background: $green;
            color: $white;
            padding: 12px;
            font-size: 2vw;
            width: 25%;
            margin: 2.5% auto;
            cursor: pointer;
            filter: drop-shadow(0px 5px 10px black);
        }

        #magicCalendarsSubtitle {
            width: 55vw;
            filter: drop-shadow(0px 4px 8px black);
        }
    }

    section#personalizationSection {
        height: 100%;
        #personalizationSectionLeft {
            width: 50%;
            display: inline-block;
            padding: 5%;
            box-sizing: border-box;

            h1 {
                font-family: "Garbata";
                font-weight: 300;
                font-size: 5.5vw;
            }

            img {
                width: 42vw;
                height: 42vw;
            }
        }

        ul {
            margin: 5%;
            font-family: 'Garet';
            font-size: 2vw;
            
            li {
                list-style: disc;
                margin-bottom: 25px;

                &.one {
                    span.one {
                        color: $pink;
                    }
                }

                &.two {
                    span.one, span.three {
                        color: $pink;
                    }
                }
                
                &.three {
                    span.three {
                        color: $pink;
                    }
                }
            }
        }

        #personalizationSectionRight {
            width: 50%;
            display: inline-block;
            img {
                width: 90%;
                transform: translateY(-25%);
                padding: 10px;
                border: 1px solid;
                border-radius: 50%;
            }
        }
    }

    section#feedSection {
        display: flex;
        height: fit-content;
        padding: 2% 0;

        img {
            width: 50%;
        }

        #feedSectionRight {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .feedSectionRightSection {
                padding: 0 10%;

                h1 {
                    font-family: 'Garbata';
                    font-size: 2.2vw;
                }

                h2 {
                    font-family: 'Garbata';
                    font-size: 1.5vw;
                    margin: 7px 0;
                    font-weight: 300;
                }

                p {
                    font-family: 'Garet';
                    font-size: 1.3vw;
                }
            }
        }
    }

    section#whatYouGetSection {
        padding: 5%;
        display: flex;
        height: 100%;
        width: calc(100vw - 12%);

        #whatYouGetLeftSection {
            > span {
                background: $green;
                color: white;
                padding: 1vw;
                font-family: "Garet";
                text-transform: uppercase;
                font-weight: 600;
                display: block;
                width: fit-content;
                font-size: 1.7vw;
                text-align: center;
                cursor: pointer;
                filter: drop-shadow(0px 5px 3px black);
            }

            > h1 {
                margin: 25px 0;
                font-family: 'Garbata';
                font-size: 4.5vw;
                position: absolute;
            }

            .whatYouGetLeftSectionSection {
                display: flex;
                flex-direction: column;

                &:nth-of-type(1) {
                    margin-bottom: 5vw;
                    margin-top: 10.5vw;
                }
    
                h2 {
                    font-family: 'Garet';
                    font-size: 1.9vw;
                }
    
                .whatYouGetLeftSectionBodySection {
                    display: flex;
                    margin-top: 5px;
                    
                    img {
                        width: 2vw;
                        height: 2vw;
                        margin-top: 0.5vw;
                        margin-right: 1vw;
                    }
                    
                    .whatYouGetLeftSectionBodySectionBody {
                        font-family: 'Garet';
                        font-size: 2vw;
    
                        span.two {
                            color: $pink;
                            font-weight: bold;
                            font-style: italic;
                            display: block;
                        }
                    }
                }
            }
        }

        #whatYouGetRightSection {
            display: flex;
            position: relative;

            #whatYouGetRightSectionLeft {
                width: 40%;
                margin: 22% 5%;
                height: 19%;
                padding: 2vw;
                background: black;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                > img {
                    width: 100%;
                }
                
                #whatYouGetSectionContentCalendarSocialIconsContainer {
                    display: flex;
                    justify-content: space-around;

                    img {
                        width: 25%;
                        border-radius: 15px;
                    }
                }
            }

            #whatYouGetRightSectionRight {
                width: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .whatYouGetRightSectionRightTitleContainer {
                    display: flex;

                    img {
                        width: 2vw;
                        height: 2vw;
                        margin-top: 0.4vw;
                        transform: translateX(-1vw);
                    }


                    h2 {
                        font-family: 'Garet';
                        font-size: 1.9vw;
                    }
                }

                hr {
                    border: 1px black solid;
                }

                .whatYouGetRightSectionBodySectionBody {
                    font-family: 'Garet';
                    font-size: 2vw;
                }
            }
        }
    }

    section#fillOutSection {
        height: fit-content;
        background: black;

        h1 {
            text-align: center;
            margin-top: 10%;
            font-size: 4vw;
            color: white;
        }

        #fillOutSectionStepsContainer {
            padding: 10%;
            display: flex;
            justify-content: space-between;

            .fillOutSectionStep {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .fillOutSectionStepBody {
                    text-align: center;
                    font-family: "Garet";
                    margin-top: 25px;
                    line-height: 2.5vw;
                    font-size: 1.8vw;
                    color: white;
                }
            }

            .fillOutSectionStep:nth-of-type(1) {
                img {
                    width: 13vw;
                }

                .fillOutSectionStepBody {
                    span:nth-of-type(1) {
                        display: block;
                    }
                }
            }
            
            .fillOutSectionStep:nth-of-type(2) {
                img {
                    width: 23vw;
                }

                .fillOutSectionStepBody {
                    width: 80%;

                    span:nth-of-type(2) {
                        display: block;
                    }
                }
            }
            
            .fillOutSectionStep:nth-of-type(3) {
                img {
                    width: 23vw;
                }

                span:nth-of-type(1) {
                    display: block;
                }
            }
        }
    }

    section#chooseSection {
        height: fit-content;
        
        h1 {
            text-align: center;
            width: 80%;
            margin: auto;
            font-size: 4vw;
            margin-top: 5%;
        }

        #chooseSectionSelectionsContainer {
            display: flex;
            justify-content: space-around;
            padding: 5%;

            .chooseSectionSelection {
                width: 33%;
                background: white;
                padding: 2%;
                border: 2px black solid;
                border-radius: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    font-family: 'Garet';
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 1.7vw;
                    text-align: center;
                }

                h2 {
                    font-family: 'Garet';
                    font-weight: unset;
                    color: $green;
                    font-size: 4vw;
                    text-align: center;
                }

                hr {
                    border: 1px solid black;
                    margin-top: 0;
                }

                .chooseSectionSelectionDescription {
                    font-family: 'Garet';
                    font-size: 1.4vw;
                    margin-top:10px;

                    span:nth-of-type(1) {
                        color: $pink;
                        font-weight: 600;
                    }

                    span:nth-of-type(3) {
                        font-weight: 600;
                    }
                }

                .chooseSectionSelectionBulletsContainer {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin-top: 25px;

                    .chooseSectionSelectionBullet {
                        display: flex;
                        font-family: 'Garet';

                        img {
                            width: 1.5vw;
                            height: 1.5vw;
                            margin-right: 0.6vw;
                            transform: translateY(0.2vw);
                        }

                        p {
                            font-size: 1.3vw;
                        }
                    }
                }

                .chooseSectionSelectionCta {
                    background: $green;
                    width: 100%;
                    padding: 5%;
                    font-family: 'Garet';
                    text-transform: uppercase;
                    color: white;
                    font-weight: 600;
                    font-size: 1.3vw;
                    cursor: pointer;
                    border: none;
                    margin-top: 25px;
                    filter: drop-shadow(0px 5px 3px black);
                }
            }
        }
    }

    section#innovateSection {
        display: flex;
        flex-direction: column;

        #innovateSectionWandContainer {
            margin-left: 10vw;
            margin-top: 10vw;
            position: absolute;

            #innovateSectionWand {
                width: 33vw;
            }
            #innovateSectionMagic {
                position: absolute;
                right: -12vw;
                bottom: -15vw;
                width: 19vw;
            }
        }

        #innovateSectionContentContainer {
            margin: auto;
            text-align: center;
            margin-left: 50%;
            margin-right: 10%;

            div {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 5vw;
                    margin-right: 1.5vw;
                }
                h4 {
                    font-family: 'Garet';
                }
            }
            h1 {
                font-family: 'Garbata';
                font-size: 3.1vw;
                font-weight: 300;
            }
            p {
                font-family: 'Garet';
                font-weight: 100;
                margin: 25px;
                font-size: 1.5vw;
            }
            button {
                background: $green;
                font-family: 'Garet';
                text-transform: uppercase;
                width: 50%;
                font-size: 1.5vw;
                font-weight: 700;
                padding: 1vw;
                color: $white;
                border: none;
                cursor: pointer;
                filter: drop-shadow(0px 5px 3px black);
            }
        }
    }
}

@keyframes flash {
    0% {
        filter: opacity(0);
    }
    40% {
        filter: opacity(0);
    }
    50% {
        filter: opacity(1);
    }
    60% {
        filter: opacity(0);
    }
    100% {
        filter: opacity(0);
    }
}