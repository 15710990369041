@import '../../colors.scss';

main#superClassPage {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    scroll-behavior: smooth;
    flex-direction: column;

    > .animate__animated {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    h1 {
        color: $black;
        width: 75%;
        margin: auto;
        font-size: 8vw;
        text-align: center;
        font-family: 'Garbata';
        font-weight: 300;
        margin-top: 10%;
    }

    mux-player {
        width: 80%;
        margin: 5% auto 0 auto;
    }

    ul#superClassFeaturesList,
    ul#superClassPerfectForList,
    #superClassClassContentTechnicalitiesContainer,
    #superClassClassContentImportantiesContainer {
        width: 80%;
        margin: auto;
        text-align: justify;

        li {
            font-family: 'Garet';
            font-size: 2vw;

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }

            img {
                transform: translate(-1vw, 0.5vw);
                width: 2.5vw;
            }

            b {
                margin-right: 1vw;
            }
        }
    }

    a {
        background: $green;
        color: $white;
        font-family: "Garbata";
        font-weight: 600;
        width: 25%;
        margin: 5% auto;
        padding: 2%;
        padding-top: 1.4%;
        font-size: 2.5vw;
        text-align: center;

        &#superClassCta2 {
            width: 25%;
        }
        
        &:hover {
            color: $yellow;
            background: $pink;
        }
    }

    #superClassPriceContainer {
        margin: 2.5% auto 5% auto;
        font-family: 'Garet';
        font-weight: bold;
        font-size: 5vw;
    }

    img#superClassSatisfactionGuarentee {
        width: 33%;
        margin: auto;
    }
    
    section#superClassBrands {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: fit-content;
        padding-bottom: 10vh;

        #brandsHelped {
            margin-top: 0;
        }
        
        #brandsHelped .brandLogo {
            width: 12%;
        }
        
        #brandsCollaborated .brandLogo {
            width: 10%;
        }
        
        #brandsFeatured .brandLogo {
            width: 10%;
        }
        
        .brandLogosContainer {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
        }
        
        #brandsHelped .brandLogosContainer {
            padding: 0 10vw;
        }
        
        #brandsCollaborated .brandLogosContainer {
            padding: 0 3vw;
        }
        
        #brandsFeatured .brandLogosContainer {
            padding: 0 5vw;
        }
        
        #brandsCollaborated>div>img:nth-child(1) {
            transform: scale(0.8);
        }
        
        #brandsCollaborated>div>img:nth-child(2) {
            transform: scale(0.9);
            filter: invert(1);
        }
        
        #brandsCollaborated>div>img:nth-child(3) {
            transform: scale(0.8);
        }
        
        #brandsCollaborated>div>img:nth-child(4) {
            transform: scale(0.5);
        }
        
        #brandsCollaborated>div>img:nth-child(5) {
            filter: drop-shadow(1px 1px 1px 1px black)
        }
        
        #brandsCollaborated>div>img:nth-child(6) {
            transform: scale(0.7);
        }
        
        #brandsCollaborated>div>img:nth-child(7) {
            transform: scale(0.8);
        }
        
        #brandsCollaborated>div>img:nth-child(8) {
            transform: scale(0.95);
        }
        
        #brandsCollaborated>div>img:nth-child(9) {
            transform: scale(0.7);
            filter: invert(1);
        }
        
        #brandsCollaborated>div>img:nth-child(10) {
            transform: scale(0.8);
        }
        
        #brandsFeatured>div>img:nth-child(3) {
            transform: scale(0.7);
        }

        #brandsFeatured>div>img:nth-child(6) {
            transform: scale(1.3);
        }

        #brandsFeatured>div>img:nth-child(7) {
            transform: scale(0.7);
            filter: invert(1)
        }
        
        #brandsFeatured>div>img:nth-child(8) {
            filter: invert(1)
        }
        
        #brandsHelped h3,
        #brandsCollaborated h3,
        #brandsFeatured h3,
        #brandsFeatured h4 {
            font-family: "Garbata";
            text-align: center;
            font-size: 1.8vw;
            margin-bottom: 3vh;
        }
        
        #brandsFeatured h3,
        #brandsFeatured h4 {
            font-size: 3vw;
        }
        
        #brandsFeatured h4 {
            margin: 4vh auto 0 auto;
            font-family: 'Hatton';
            color: black;
            font-size: 4vw;
        }
        
        .brandsSection {
            margin-top: 5vw;
            margin-bottom: 0;
        }
    }

    .react-multiple-carousel__arrow {
        min-width: 75px !important;
        min-height: 75px !important;
        clip-path: circle(40%);
        box-shadow: -8px 11px 2px 3px black;
        background: $pink !important;
        transition: 300ms;

        &:hover {
            transform: scale(1.2);
        }
        
        &:active {
            transform: scale(0.8);
        }
    }

    .react-multi-carousel-item {
        filter: blur(2px);
    }

    .react-multi-carousel-item--active {
        filter: none !important;
    }

    .react-multi-carousel-list {
        padding: 10% 0;
    }

    .superClassTestimonialContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        img {
            height: 33vw;
            width: 33vw;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid $pink;
        }

        p {
            font-family: 'Garet';
            font-size: 2vw;
            width: 67%;
            margin: 5% 0px;
            font-style: italic;
        }
            
        div {
            text-align: center;
            h4 {
                color: $pink;
                font-size: 3vw;
            }

            h5 {
                font-size: 2vw;
                margin: 1% 0;
            }

            h6 {
                font-size: 1.5vw;
            }
        }
    }

    #superClassPerfectForListContainer {
        #superClassPerfectForList {
            transform: translateX(4%);
        }

        h2 {
            text-align: center;
            margin: 5% 0;
            font-family: 'Garbata';
            font-size: 4vw;
        }
    }

    div#superClassClassContentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2, h3 {
            font-family: 'Garbata';
        }

        h2 {
            font-weight: bold;
            margin: 5% 0;
            font-size: 4vw;
        }

        h3 {
            margin: 5% 0;
            font-size: 3.5vw;
        }

        li {
            font-family: 'Garet';

            img {
                // width: 3vw;
            }
        }

        #superClassClassContentTechnicalitiesContainer,
        #superClassClassContentImportantiesContainer {
            width: 75%;
        }

        #superClassClassContentImportantiesContainer {
            margin-bottom: 5%;
        }
    }
}