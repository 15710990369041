#backIcon,
#backText {
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 98;
}

#backIcon {
    height: 5vw;
    width: 5vw;
}

#backText {
    font-family: 'Hatton';
    font-size: 2vw;
}

@media (max-width: 640px) {
    #backText {
        font-size: 4vw;
    }
}