@import '../../colors.scss';

@media (min-width: 640px) and  (max-width: 1024px) {
    #magicCalendarsPage {
        section#titleSection {
            padding-top: 5%;
            
            #magicCalendarsTitle {
                width: 50% !important;
                margin-top: 0%;
            }

            img#magicCalendarsTitleWand {
                top: 12% !important;
                right: 19% !important;
                width: 17vw !important;
            }

            h2 {
                padding: 12px;
                font-size: 2vw;
                width: 29%;
            }

            #magicCalendarsSubtitle {
                width: 67%;
            }
                        
            #magicCalendarsTitleSectionWandContainer {
                animation: hoverMedium infinite 10000ms ease-in-out;

                #magicCalendarsTitleSectionWand {
                    width: 50vw;
                }

                #magicCalendarsTitleSectionWandMagic {
                    width: 20vw;
                    position: absolute;
                    top: 35%;
                    right: -14%;
                    z-index: -1;
                }
            }

            #magicCalendarsTitleSectionComputerHands {
                width: 50%;
                left: 25%;
            }
        }
    }

    section#personalizationSection {
        div {
            h1 {
                font-size: 4.8vw !important;
            }
        }

        ul {
            font-size: 2vw !important;
        }

        #personalizationSectionRight {
            img {
                transform: translate(-5%, -15%) !important;
            }
        }
    }

    section#whatYouGetSection {
        #whatYouGetLeftSection {
            > span {
                width: 100% !important;
            }
            .whatYouGetLeftSectionSection {
                h2 {
                    font-size: 1.6vw !important;
                }

                .whatYouGetLeftSectionBodySection {
                    .whatYouGetLeftSectionBodySectionBody {
                        font-size: 1.3vw !important;
                    }
                }
            }
        }

        #whatYouGetRightSection {
            #whatYouGetRightSectionLeft {
                height: 42% !important;
                margin: 30% 5% 15% !important;
            }

            #whatYouGetRightSectionRight {
                .whatYouGetRightSectionRightTitleContainer {
                    img {
                        width: 2vw !important;
                        height: 2vw !important;
                        margin-top: 0.1vw !important;
                        transform: translateX(-0.7vw) !important;
                    }

                    h2 {
                        font-size: 1.6vw !important;
                    }
                }

                .whatYouGetRightSectionBodySectionBody {
                    font-size: 1.4vw !important;
                }
            }
        }
    }

    section#feedSection {
        img {
            margin-left: 3% !important;
            margin-right: -2% !important;
        }
    }

    section#fillOutSection {
        #fillOutSectionStepsContainer {
            .fillOutSectionStep {
                .fillOutSectionStepBody {
                    font-size: 1.6vw !important;
                }
            }
        } 
    }

    section#chooseSection {
        h1 {
            width: 90% !important;
        }
        #chooseSectionSelectionsContainer {
            .chooseSectionSelection {
                .chooseSectionSelectionDescription {
                    font-size: 1.4vw !important;
                }

                .chooseSectionSelectionBulletsContainer {
                    .chooseSectionSelectionBullet {
                        img {
                            margin-top: 0vw;
                            margin-right: 0.7vw !important;
                        }
                        p {
                            font-size: 1.3vw !important;
                        }
                    }
                }
                
                .chooseSectionSelectionCta {
                    font-size: 1.5vw !important;
                }
            }
        }
    }

    section#innovateSection {
        #innovateSectionWandContainer {
            #innovateSectionWand {
                width: 25vw !important;
            }
            #innovateSectionMagic {
                right: -12vw !important;
                bottom: -19vw !important;
                width: 19vw !important;
            }
        }
        #innovateSectionContentContainer {
            margin-left: 47% !important;
            h1 {
                font-size: 4.5vw !important;
            }
            p {
                font-size: 1.9vw !important;
            }
            button {
                width: 75% !important;
                font-size: 2vw !important;
            }
        }
    }
}

@keyframes hoverMedium {
    0% {
        left: -33%;
        top: 25%;
    }

    40% {
        left: 0%;
        top: 55%;
    }

    60% {
        left: 0%;
        top: 55%;
    }

    100% {
        left: -33%;
        top: 25%;
    }
}