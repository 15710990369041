@import '../../../colors.scss';

#superWatcherPageContainer {
    background: black;
    color: white;
    border: 1px solid $pink;
    padding: 10%;
    padding-bottom: 0;
    text-align: center;
    min-height: 100vh;
    position: relative;

    a#superWatcherGoBackButton {
        font-family: 'Hatton';
        position: absolute;
        left: 10%;
        top: 3%;
        font-size: 2vw;
    }
        
    #superWatcherLoading {
        margin: auto;
    }

    mux-player {
        margin-bottom: 10%;
        border: 2px solid $yellow;
        box-shadow: 0px 0px 80px -25px white;
        border-radius: 15px;
        overflow: hidden;
        margin: 0 5%;
    }

    .superClassLogoutButton {
        color: $yellow;
        font-family: 'Hatton';
        background: none;
        cursor: pointer;
        font-size: 2vw;
        position: absolute;
        top: 3%;
        right: 5%;
    }

    h1 {
        margin-top: 10%;
        margin-bottom: 10%;
        font-size: 5vw;
        font-family: 'Garbata';

        #superWatcherTitleNumber {
            font-family: 'Hatton';
            font-size: 4.8vw;
            color: $yellow;
        }
    }

    #superWatcherVideoContainer {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 15%;

        > svg {
            cursor: pointer;
        }
        
        #superWatcherCountdownContainer {
            position: absolute;
            top: -5vw;
            background: black;
            border-radius: 50%;
            right: -4vw;
            z-index: 100;

            #superWatcherCountdownText {
                font-family: 'Garet';
                width: 67%;

                svg {
                    height: 30px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    video {
        width: 100%;
    }

    .scroll-to-top {
        background: $yellow;
    }
}