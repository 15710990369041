@import '../../colors.scss';

@media (max-width: 640px) {
    #blogPageContainer {
        #blogPageHilightsContainer {
            flex-direction: column;
            padding: 50px 0 !important;

            a {
                width: 90% !important;
                margin: auto;
            }

            .blogPageBlogPostContainer {
                font-size: 3vw !important;
                margin-bottom: 50px
            }
        }
    }
}
