@import '../../../colors.scss';

@media (max-width: 640px) {
    .teamMemberContainer {
        justify-content: flex-end;
        .teamMemberPhoto {
            width: 20vw !important;
            height: 20vw !important;
        }

        .teamMemberTextContainer {
            font-size: 2.5vw !important;

            .teamMemberName {
                font-size: 3vw !important;
            }
        }

        img#teamHomeIcon {
            height: 10vw !important;
            top: -200px !important;
            width: 10vw !important;
        }
    }
}