@import '../../../colors.scss';

@media (min-width: 1440px) {
    #superLoginPageContainer {
        h1 {
            margin: 2.5% auto 2.5% auto;
            font-size: 2.5vw;
        }

        div#superLoginFieldsContainer {
            width: 40%;
            margin-bottom: 2.5%;

            .superLoginField {
                &:nth-of-type(1) {
                    margin-bottom: 25px;
                }

                label {
                    font-size: 1.3vw;
                }

                input {
                    min-height: 50px;
                    width: 75%;
                    font-size: 1.2vw;
                    padding: 0 20px;
                }
            }
        }

        div#superLoginButtonContainer {
            position: relative;

            button {
                font-size: 1.3vw;
                width: 7%;
            }

            p#superClassLoginError {
                margin-top: 2.5%;
                font-size: 1.5vw;
            }
        }
    }
}