@import '../../colors.scss';

@media (max-width: 640px) {
    #meetTheTeamPage {
        flex-direction: column;

        .right {
            width: 100% !important;
            flex-direction: column !important;
        }

        #teamTitleContainer {
            font-size: 7vw !important;
            height: 28% !important;
            width: 100% !important;

            #teamDownwardSpiral {
                top: -5vh;
                right: unset !important;
                left: 37vw;
                width: 30vmin !important;
            }
        }
    }
}