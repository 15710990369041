@import '../../../../colors.scss';

.navbarLink {
    text-decoration: none;
    font-family: "Garbata";
    font-weight: 300;
    font-size: 1.4vw;
    z-index: 97;
    position: relative;
}

.navbarTooltip {
    cursor: help;
    display: flex !important;
    align-items: center;
}

.tippy-popper {
    font-family: 'Garbata';
    font-weight: bold;
}