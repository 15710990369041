@import '../../colors.scss';

#servicesPage {
    .servicesSection {
        display: flex;

        label {
            text-transform: uppercase;
            font-family: "Garet";
            font-size: 1.5vw;
        }

        h2 {
            font-family: "Garbata";
            font-size: 3.7vw;
            margin-top: 1%;
        }

        p {
            font-size: 1.5vw;
            font-family: "Garet";
            margin-top: 5%;
        }

        .servicesSectionLeft,
        .servicesSectionRight {
            width: 50%;
            padding: 5%;
        }

        .servicesSectionLeft {
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                width: 100%;
                max-width: 500px;
                margin: auto;
            }
        }

        .servicesSectionRight {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 10%;
        }
    }

    section#servicesTitleSection {
        display: flex;

        div#servicesTitleLeft {
            width: 33%;
            display: flex;
            flex-direction: column;
            padding: 10% 0 5% 10%;

            h1 {
                font-size: 3.5vw;
            }

            p {
                font-size: 1.5vw;
                font-family: "Garet";
            }

            button {
                font-family: "Garbata";
                font-weight: 700;
                font-size: 1.8vw;
                background: $green;
                color: white;
                padding: 1vw 2vw;
                display: block;
                width: fit-content;
                margin: 10% 0;
                cursor: pointer;
            }
        }

        div#servicesTitleRight {
            width: 50%;
            padding-left: 2.5%;

            #servicesTitleRight {
                width: 100%;
                margin-right: 10%;
                margin-top: 10%;
            }

            #servicesTitleVideo {
                margin-top: 20%;
            }
        }
    }

    section#servicesUgcSection {
        div#servicesUgcLeft {
            video#servicesUgcVideo {
                width: 50%;
                margin: auto;
            }
        }
    }

    section#servicesBrandSection {}

    section#servicesDigitalSection {
        #servicesDigitalLeft {
            img {
                margin-top: 20%;
            }
        }
    }

    section#servicesAccelerationSection {
        #servicesAccelerationLeft {
            img {
                margin-top: 15%;
            }
        }
    }

    section#servicesPlanSection {
        padding: 5% 10%;

        h3 {
            font-family: "Garbata";
            font-size: 2vw;
        }

        ul#servicesPlanBulletList {
            display: flex;
            flex-direction: column;
            margin-top: 5%;

            li.servicesPlanBullet {
                font-family: "Garbata";
                font-size: 1.6vw;
                font-weight: 300;
                margin-bottom: 5%;
                display: flex;
                align-items: center;
                width: 85%;

                &:nth-of-type(1) {
                    margin-bottom: 1%;
                }

                img {
                    width: 2.5vw;
                    height: 2.5vw;
                    margin-right: 1vw;
                }
            }

            ul#servicesPlanBulletOneList {
                display: flex;
                flex-direction: column;
                margin-left: 5%;
                margin-bottom: 5%;

                li {
                    font-family: "Garet";
                    font-size: 1.2vw;
                }
            }
        }
    }

    section#servicesPlansAndPricesSection {
        padding: 0% 10% 10%;

        h3 {
            font-family: "Garbata";
            font-size: 3.7vw;
            margin-bottom: 2%;
            text-align: center;
        }

        div#servicesPlansAndPricesContainer {
            display: flex;
            justify-content: space-between;

            .servicesPlansAndPrices {
                position: relative;
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .servicesPlansAndPricesContent {
                    h4 {
                        font-family: "Garbata";
                        font-size: 1.5vw;
                        margin-top: 5%;
                    }

                    label {
                        color: $green;
                        font-family: "Garet";
                        text-align: right;
                        display: block;
                        font-size: 1vw;
                        position: absolute;
                        right: 20%;
                        top: 9%;
                    }

                    p {
                        font-family: "Garet";
                        font-size: 1vw;
                        width: 75%;
                        margin: 10% 0 5%;
                    }

                    ul.servicesPlansAndPricesList {
                        display: flex;
                        flex-direction: column;
                        margin-top: 5%;

                        li.servicesPlansAndPricesItem {
                            list-style: disc;
                            font-family: "Garet";
                            font-size: 1vw;
                            margin-bottom: 0%;
                            margin-left: 5%;
                            width: 75%;
                        }
                    }
                }

                a.checkoutButton {
                    background: $green;
                    color: white;
                    padding: 5%;
                    font-family: "Garbata";
                    font-weight: 700;
                    width: 75%;
                    margin-top: 7%;
                    cursor: pointer;
                    font-size: 1.1vw;
                    text-align: center;
                }
            }
        }
    }

    section#servicesUgcPackagesSection {
        display: flex;
        padding: 0% 10% 10%;
        justify-content: space-between;

        video#servicesUgcPackagesVideo {
            width: 30%;
            border-radius: 50px;
            padding: 15px;
            border: 3px solid;
        }

        div#servicesUgcPackagesRight {
            transform: translateX(15%);

            ul {
                margin: 5% 0 14%;
            }
        }

        .servicesUgcPackage {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 30%;
            height: 50%;

            h4 {
                font-family: "Garbata";
                font-size: 1.5vw;
                margin: 25% 0 5%;

                span.servicesUgcTitleNumber {
                    font-family: 'Hatton';
                    font-size: 1.3vw;
                }
            }

            label {
                color: $green;
                font-family: "Garet";
                text-align: right;
                display: block;
                font-size: 1vw;
                position: absolute;
                right: 31%;
                top: 26%;
            }

            p {
                font-family: "Garet";
                font-size: 1vw;
                margin: 10% 0 5%;
                width: 75%;
            }

            ul {
                display: flex;
                flex-direction: column;
                margin-top: 5%;

                li {
                    list-style: disc;
                    font-family: "Garet";
                    font-size: 1vw;
                    margin-bottom: 0%;
                    margin-left: 5%;
                    width: 75%;
                }
            }

            a.checkoutButton {
                background: $green;
                color: white;
                padding: 5%;
                font-family: "Garbata";
                font-weight: 700;
                width: 75%;
                margin-top: 7%;
                cursor: pointer;
                font-size: 1.1vw;
                text-align: center;
            }
        }
    }

    #servicesAlexTestimonial {
        margin-bottom: 5%;
    }

    .servicesTestimonial {
        margin-bottom: 10%;
        display: flex;

        .left {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 10%;
        }

        p {
            font-size: 1.8vw;
            font-family: "Garbata";
            font-style: italic;
            width: 72%;
            margin-bottom: 10%;
        }

        .right {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;
        }

        .testimonialLabel {
            display: flex;
            flex-direction: column;
            font-size: 2.3vw;
            font-family: "Garbata";
            font-weight: 700;
            font-style: italic;
            color: $green;
        }

        img.testimonialPhone {
            width: 58%;
        }
    }

    section#servicesContactSection {
        display: flex;

        div#servicesContactLeft {
            width: 50%;
            padding: 5%;

            h4 {
                font-family: "Garbata";
                font-size: 2.5vw;
                margin-bottom: 5%;
            }

            h3 {
                font-family: "Garbata";
                font-size: 5vw;

                &:nth-of-type(2) {
                    margin-bottom: 5%;
                }
            }

            p#servicesContactDescription {
                font-family: "Garet";
                font-size: 1.5vw;
                margin-bottom: 5%;
            }

            p#servicesContactTagline {
                font-family: "Garbata";
                font-size: 3vw;
                font-weight: 300;
                margin-bottom: 5%;
            }

            div.servicesContactButton {
                font-family: "Garet";
                border-radius: 25px;
                font-size: 1.5vw;
                background: none;
                border: 1px solid black;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 2.5%;
                margin-bottom: 2.5%;
                align-items: center;

                &:last-child {
                    background: $green;
                    color: white;
                }

                img {
                    width: 1vw;
                }
            }
        }

        div#servicesContactRight {
            width: 50%;
            display: flex;

            img {
                width: 90%;
                margin: auto auto auto 0;
                display: none;
            }
        }
    }
}