@import '../../colors.scss';

@media (max-width: 640px) {
    #magicCalendarsPage {
        section#titleSection {
            padding-top: 10%;

            #magicCalendarsTitle {
                width: 80% !important;
            }
            
            img#magicCalendarsTitleWand {
                top: 15% !important;
                right: 3% !important;
                width: 26vw !important;
            }

            h2 {
                padding: 10px;
                font-size: 4vw;
                width: 50%;
                margin: 5% auto;
            }

            #magicCalendarsSubtitle {
                width: 90%;
            }

            #magicCalendarsTitleSectionWandContainer {
                animation: hoverSmall infinite 10000ms ease-in-out;

                #magicCalendarsTitleSectionWand {
                    width: 67vw;
                }
    
                #magicCalendarsTitleSectionWandMagic {
                    width: 30vw;
                    position: absolute;
                    top: 33%;
                    right: -17%;
                    z-index: -1;
                    animation: flash infinite 10000ms ease-in-out;
                }
            }

            #magicCalendarsTitleSectionComputerHands {
                width: 80%;
                left: 10%;
            }
        }
    }

    section#personalizationSection {
        display: flex;
        flex-direction: column;
        div#personalizationSectionLeft {
            width: 100% !important;
            h1 {
                font-size: 6vw !important;
            }
    
            ul {
                font-size: 3.5vw;
            }
        }
        div#personalizationSectionRight {
            width: 100% !important;
            margin-bottom: 5% !important;
            display: flex !important;
            justify-content: center;
            img {
                width: 67% !important;
                transform: none !important;
            }
        }
    }

    section#feedSection {
        display: flex;
        height: fit-content;
        flex-direction: column;
        padding-top: 0 !important;

        img {
            width: 100% !important;
            margin-bottom: 25px;
        }

        #feedSectionRight {
            .feedSectionRightSection {
                padding: 0 5% !important;
                margin-bottom: 25px;

                h1 {
                    font-size: 7vw !important;
                }

                h2 {
                    font-size: 3.6vw !important;
                }

                p {
                    font-family: 'Garet';
                    font-size: 2.9vw !important;
                }
            }
        }
    }

    section#whatYouGetSection {
        flex-direction: column;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        #whatYouGetLeftSection {
            width: 100% !important;

            .whatYouGetLeftSectionSection:nth-of-type(1) {
                margin-top: 0 !important;
            }

            >span {
                font-size: 4vw !important;
                padding: 10px !important;
                margin: auto;
            }

            >h1 {
                font-size: 7vw !important;
                position: relative !important;
            }

            .whatYouGetLeftSectionSection {
                h2 {
                    font-size: 3.5vw !important;
                }

                img {
                    width: 6vw !important;
                    height: 3vw !important;
                }

                .whatYouGetLeftSectionBodySection {
                    .whatYouGetLeftSectionBodySectionBody {
                        font-size: 2.9vw !important;
                    }
                }
            }
        }

        #whatYouGetRightSection {
            flex-direction: column;

            #whatYouGetRightSectionLeft {
                height: 50% !important;
                margin: 5% auto !important;
                width: 80% !important;

                > img {
                    margin-bottom: 12.5px;
                }
            }

            #whatYouGetRightSectionRight {
                width: 100% !important;

                .whatYouGetRightSectionRightTitleContainer {
                    img {
                        width: 3vw !important;
                        height: 3vw !important;
                        margin-top: 0 !important;
                        transform: translate(-1vw, 0.9vw) !important;
                    }

                    h2 {
                        font-size: 3.5vw !important;
                    }
                }

                .whatYouGetRightSectionBodySectionBody {
                    font-size: 2.9vw !important;
                    margin-bottom: 25px;
                }
            }
        }
    }

    section#fillOutSection {
        #fillOutSectionStepsContainer {
            flex-direction: column;
            height: 100%;
            justify-content: space-around !important;

            h1 {
                font-size: 5vw !important;
            }

            .fillOutSectionStep {
                .fillOutSectionStepBody {
                    line-height: 6vw !important;
                    font-size: 3.6vw !important;
                }
            }

            .fillOutSectionStep:nth-of-type(1) {
                margin-bottom: 25px;

                img {
                    width: 30vw !important;
                }
            }
            
            .fillOutSectionStep:nth-of-type(2) {
                margin-bottom: 25px;

                img {
                    width: 50vw !important;
                }

                .fillOutSectionStepBody {
                    width: 80%;
                }
            }
            
            .fillOutSectionStep:nth-of-type(3) {
                img {
                    width: 50vw !important;
                }
            }
        }
    }

    section#chooseSection {
        h1 {
            font-size: 5vw !important;
        }
        
        #chooseSectionSelectionsContainer {
            padding: 5%;
            flex-direction: column;
            align-items: center;

            .chooseSectionSelection {
                width: 90% !important;
                padding: 5% !important;

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 25px;
                }

                h3 {
                    font-size: 4.6vw !important;
                }

                h2 {
                    font-size: 9vw !important;
                }

                .chooseSectionSelectionDescription {
                    font-size: 3.6vw !important;
                }

                .chooseSectionSelectionBulletsContainer {
                    .chooseSectionSelectionBullet {
                        img {
                            width: 4vw !important;
                            height: 4vw !important;
                            margin-right: 1.6vw !important;
                            margin-top: 0.3vw;
                        }

                        p {
                            font-size: 3.1vw !important;
                        }
                    }
                }

                .chooseSectionSelectionCta {
                    font-size: 4.3vw !important;
                }
            }
        }
    }

    section#innovateSection {
        #innovateSectionWandContainer {
            margin-left: 10vw !important;
            margin-top: 10vw !important;

            #innovateSectionMagic {
                right: -21vw !important;
                bottom: -34vw !important;
                width: 31vw !important;
            }
        }

        #innovateSectionContentContainer {
            margin-left: 0% !important;
            margin-right: 0% !important;

            div {
                img {
                    width: 21vw !important;
                    margin-right: 3.7vw !important;
                }
                h4 {
                    font-size: 5vw !important;
                }
            }
            h1 {
                font-size: 10vw !important;
            }
            p {
                font-size: 3.8vw !important;
            }
            button {
                width: 75% !important;
                font-size: 4.5vw !important;
                padding: 3.5vw !important;
            }
        }
    }
}

@keyframes hoverSmall {
    0% {
        left: -33%;
        top: 33%;
    }

    40% {
        left: 0%;
        top: 55%;
    }

    60% {
        left: -15%;
        top: 55%;
    }

    100% {
        left: -33%;
        top: 33%;
    }
}