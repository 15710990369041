@import '../../../colors.scss';

@media (max-width: 1024px) {
    #magicCheckoutPage {
        #magicCheckoutTitleSectionContainer {
            margin-top: 5%;
            position: relative;

            #magicCheckoutTitleSectionWandContainer {
                top: -2vw;
                left: 41vw;
            }
        }

        .magicCheckoutQuestion {
            .magicCheckoutQuestionInputContainer {
                flex-direction: column;
                width: 100%;

                h2 {
                    font-size: 4vw;
                    margin: 2% 0px;
                    text-align: center;
                }

                input,
                textarea {
                    font-size: 16px;
                    width: 75%;
                }

                .magicCheckoutError {
                    position: relative !important;
                    right: 0 !important;
                    font-size: 2.4vw !important;
                }
            }

            &.six {
                #magicCheckoutColorPickersContainer {
                    width: 100%;
                    margin: 5% 0;
                }
            }

            &.seven {
                #magicCheckoutEmojiPickersContainer {
                    width: 100%;
                    margin: 5% 0;
                }
            }

            &.eight {
                #magicCheckoutQuestionEightToggleContainer {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: unset !important;
                    margin-top: 2%;

                    .react-toggle {
                        margin-bottom: 8px;
                    }

                    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
                        margin-top: 5% !important;
                        margin-left: unset !important;
                        margin-bottom: 5%;
                        width: 200px;
                    }
                }
            }

            &.nine {
                #magicCheckoutQuestionNineToggleContainer {
                    margin-top: 0;
                    flex-direction: column;
                    gap: 10px;
                }

                .magicCheckoutQuestionInputContainer:nth-of-type(1) {
                    height: unset !important;
                }

                #magicCheckoutGraphicsContainer {
                    // h4 {
                    //     display: none;
                    // }

                    svg {
                        margin-top: 10px;
                    }

                    .magicCheckoutGraphicContainer {
                        margin: 2% auto 0;
                    }
                }
            }

            &.ten {
                #magicCheckoutQuestionTenRadioContainer {
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-evenly;

                    .styleTooltip {
                        width: 50%;

                        input {
                            width: 5vw;
                            height: 5vw;
                        }
                    }
                }
            }
        }

        #chooseSectionSelectionsContainer {
            .chooseSectionSelection {
                .chooseSectionSelectionDescription {
                    font-size: 1.9vw;
                }
            }
        }
    }
}