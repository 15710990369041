@import '../../../colors.scss';

#magicCheckoutPage {
    padding: 7%;

    h1 {
        font-size: 6vw;
        font-family: 'Garbata';
        font-weight: 300;
        margin-bottom: 5%;
    }

    h2 {
        font-weight: 300;
        font-size: 1.7vw;
        margin-right: 10px;
        display: inline-flex;
        font-family: "Garbata";
    }

    .magicCheckoutError {
        margin: 2% auto;
        color: $pink;
        position: absolute;
        right: 10%;
        font-size: 1.2vw;
    }

    .magicCheckoutQuestion {
        border: 1px solid $pink;
        padding: 2%;
        border-radius: 15px;
        background: $pinkFaded;
        margin: 2% 0;

        .magicCheckoutQuestionInputContainer {
            display: flex;
            align-items: center;
            width: 67%;
            justify-content: space-between;
        }

        .magicCheckoutInput {
            font-family: 'Garet';
            border: 1px solid black;
            font-weight: 300;
            height: 30px;
            border-radius: 15px;
            width: 50%;
            padding: 0 2%;
            margin-left: 2%;
            font-size: 1.2vw;
        }

        .magicCheckoutTextarea {
            font-family: 'Garet';
            border: 1px solid black;
            height: 100px;
            border-radius: 15px;
            width: 50%;
            padding: 1% 2%;
            font-size: 1.2vw;
        }

        &.four {
            display: flex;
            align-items: center;
        }

        &.six {
            #magicCheckoutColorPickersContainer {
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 55%;

                .w-color-alpha:nth-of-type(2) {
                    display: none;
                }

                .magicColorPickerContainer:nth-of-type(1) {
                    .magicColorPicker {
                        left: 0;
                    }
                }

                .magicColorPickerContainer:nth-of-type(5) {
                    .magicColorPicker {
                        right: 0;
                    }
                }
            }
        }

        &.seven {
            #magicCheckoutEmojiPickersContainer {
                display: flex;
                width: 55%;
                height: 50px;
            }
        }

        &.eight {
            .ReactFlagsSelect-module_flagsSelect__2pfa2 {
                font-family: 'Garbata';
                font-weight: 300;
                display: inline-block;
                margin-left: 10%;
                padding-bottom: 0;
                margin-top: -6px;
                background: white;

                button {
                    font-weight: 300;
                }
            }

            #magicCheckoutQuestionEightToggleContainer {
                width: 50%;
                margin-top: 3%;
                height: 50px;
            }
        }

        &.nine {
            .magicCheckoutQuestionInputContainer:nth-of-type(1) {
                width: 100%;
                height: 50px;
            }

            .magicCheckoutQuestionInputContainer:nth-of-type(2) {
                width: 100%;
                margin-top: 1%;
            }

            #magicCheckoutQuestionNineToggleContainer {
                width: 52%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            button {
                background: $pink;
                color: white;
                font-family: 'Hatton';
                font-weight: 600;
                padding: 12px 10px 10px;
                cursor: pointer;
                height: 50px;
                margin-left: 10%;
            }

            h4 {
                position: relative;
            }

            #magicCheckoutGraphicsContainer {
                display: flex;
                justify-content: space-around;
                width: 80%;
                flex-wrap: wrap;
                gap: 2%;

                .magicCheckoutGraphicContainer {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin: 20px 0;

                    h4 {
                        margin-top: 10px;
                        font-size: 1vw;
                    }

                    img,
                    video {
                        height: 50px;
                        border-radius: 5px;
                        filter: drop-shadow(0 0 2px $pink);
                    }

                    svg {
                        cursor: pointer;
                    }
                }
            }

            .uploader__submitted-file__container {
                display: none;
            }
        }

        &.ten {
            .magicCheckoutQuestionInputContainer:nth-of-type(1) {
                width: 100%;
            }

            #magicCheckoutQuestionTenRadioContainer {
                display: flex;
                width: 80%;
                justify-content: space-around;

                .magicCheckoutQuestionTenRadio {
                    display: flex;
                    flex-direction: column;
                    font-family: 'Hatton';
                    justify-content: space-between;
                    text-align: center;
                    cursor: pointer;

                    label {
                        margin: 5% 0 2%;
                        font-weight: bold;
                        // text-transform: uppercase;
                        color: $pink;
                        cursor: pointer;
                        // font-size: 2.5vw;
                    }

                    hr {
                        width: 100%;
                        margin-bottom: 15px;
                        background: $pink;
                        border: 1px solid $pink;
                        height: 0px;
                    }

                    input {
                        width: 2vw;
                        height: 2vw;
                        margin: 5% auto;
                        filter: hue-rotate(110deg);
                        cursor: pointer;

                        &::after {
                            background: $pink;
                        }
                    }
                }
            }

            .magicCheckoutQuestionInputContainer:nth-of-type(2) {
                margin-top: 3%;
            }
        }
    }

    #magicCheckoutTitleSectionWandContainer {
        position: absolute;
        top: 5vw;
        left: 50vw;

        #magicCheckoutTitleSectionWand {
            width: 12vw;
        }

        #magicCheckoutTitleSectionWandMagic {
            width: 18vw;
            transform: rotate(-112deg) scaleX(-1);
            position: absolute;
            right: -207%;
            top: -80%;
        }
    }

    #magicCheckoutSubmitButton {
        background: $green;
        color: white;
        font-size: 4vw;
        padding: 13px;
        cursor: pointer;
        font-family: 'Garbata';
        font-weight: bold;
        margin: 5%;
        margin-bottom: 0;
        width: 90%;

        &.disabled {
            background: $blackFaded !important;
            cursor: not-allowed !important;
            font-size: 3vw;
            color: #0000006b;
        }
    }

    #chooseSectionSelectionsContainer {
        display: flex;
        justify-content: space-around;
        padding: 5%;

        .chooseSectionSelection {
            width: 33%;
            background: $pinkFaded;
            padding: 2%;
            border: 1px $pink solid;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                font-family: 'Garet';
                text-transform: uppercase;
                font-weight: 600;
                font-size: 1.7vw;
                text-align: center;
            }

            h2 {
                font-family: 'Garet';
                font-weight: unset;
                color: $green;
                font-size: 4vw;
                justify-content: center;
                width: 100%;
            }

            hr {
                border: 1px solid black;
                margin-top: 0;
            }

            input[type="radio"] {
                width: 5vw;
                height: 5vw;
                margin: 5% auto;
                filter: hue-rotate(110deg);
                cursor: pointer;
            }

            .chooseSectionSelectionDescription {
                font-family: 'Garet';
                font-size: 1.4vw;
                margin-top: 10px;

                span:nth-of-type(1) {
                    color: $pink;
                    font-weight: 600;
                }

                span:nth-of-type(3) {
                    font-weight: 600;
                }
            }
        }
    }
}

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}

.w-color-block {
    --block-background-color: black !important;
    --block-box-shadow: rgb(0 0 0 / 10%) 0 1px !important;
}

.w-color-editable-input {
    font-family: 'Garet';
    --editable-input-label-color: #DA2A7D !important;
    --editable-input-box-shadow: #616161 0px 0px 0px 1px inset !important;
    --editable-input-color: #DA2A7D !important;
}

.w-color-github {
    --github-border: 1px solid rgba(0, 0, 0, 0.2) !important;
    --github-background-color: #ffffff !important;
    --github-box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px !important;
    --github-arrow-border-color: rgba(0, 0, 0, 0.15) !important;
}

.w-color-compact {
    --compact-background-color: $pink !important;
}

.w-color-material {
    --material-background-color: #323232 !important;
    --material-border-bottom-color: #707070 !important;
}

.w-color-alpha {
    --alpha-pointer-background-color: #6a6a6a !important;
    --alpha-pointer-box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px !important;
}

.uploader__widget-base__children {
    font-family: 'Garbata';
    font-weight: 300;

    .btn {
        font-weight: bold;
        font-family: 'Garbata';
    }

    .btn--primary {
        background: $pink;
        border: none;
        border-radius: 0;
        font-family: 'Garbata';

        input {
            cursor: pointer;
        }

        &:hover {
            background-color: $pink !important;
            transform: scale(1.05);
        }

        &:active {
            transform: scale(0.95);
        }
    }
}

.uploader__modal__close svg {
    color: $pink !important;

    &:hover {
        transform: scale(1.05);
    }

    &:active {
        transform: scale(0.95);
    }
}

.uploader__main-screen__info,
.uploader__image-editor__header,
.uploader__submitted-file__name {
    font-family: 'Hatton';
    font-weight: bold;
}

.uploader__submitted-file__container {
    background: $pinkFaded;
}

.uploader__submitted-file__inner {
    filter: hue-rotate(267deg);
}

.tippy-popper {
    font-family: 'Garet' !important;
    font-weight: 300 !important;
    font-size: 10px !important;
}