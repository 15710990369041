.MentionDropdownItem {
    display: flex;
    align-items: center;
    height: inherit;

    .avatar {
        background: #ffffff;
        border: 1px solid rgba(#003945, 0.3);
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .name {
        line-height: 20px;
    }
}