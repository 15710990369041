body {
    margin: unset;
}

ul {
    margin: unset;
    padding: unset;
}

li {
    list-style: none;
}

a {
    color: unset;
    text-decoration: none;
    font-weight: unset;
    
    &:hover {
        text-decoration: none;
    }
}

p {
    margin: unset;
}

h1, h2, h3, h4, h5, h6 {
    margin: unset;
    font-family: 'Hatton';
}

button, input, textarea {
    border: none;
}

.clickable {
    transition: 300ms;
    &:hover {
        transform: scale(1.1);
    }
    &:active {
        transform: scale(0.9);
    }
}

.left,
.right {
    width: 50%;
    height: 100%;
    display: inline-block;
    vertical-align: bottom;
    position: relative;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}