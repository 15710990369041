@import '../../../colors.scss';

@media (max-width: 640px) {
    #superLoginPageContainer {
        h1 {
            margin: 20vh auto 5% auto;
            font-size: 7vw;
        }

        div#superLoginFieldsContainer {
            width: 100%;

            .superLoginField {
                display: flex;
                flex-direction: column;
                label {
                    font-size: 5vw;
                }
                input {
                    width: 80%;
                    height: 40px;
                    font-size: 3vw;
                }
            }
        }

        #superLoginButtonContainer {
            button {
                width: 25% !important;
                font-size: 4.5vw !important;
            }

            p#superClassLoginError {
                font-size: 4.5vw !important;
            }
        }
    }
}