@import '../../colors.scss';

@media (min-width: 1024px) {
    #blogPageContainer {
        #blogPageTitleContainer h1 {
            display: block;
            font-size: 2vw;
            text-transform: uppercase;
        }

        #blogPageTitleContainer {
            img {
                width: 10vw;
                top: -5.5vw;
            }
        }

        #blogPageHilightsContainer a {
            h2 {
                margin-bottom: 25px;
                font-size: 1.4vw;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}