@import '../../colors.scss';

#loginPageContainer {
    background: $pink;

    input {
        color: $yellow;
    }

    #loginButton {
        color: black;
        background: $yellow;
        box-shadow: 0vw 5px 0vw 0vw $pink, 0vw 10px 0vw black;
    }
    
    .Polaris-InlineError {
        color: $yellow;
    }
}
